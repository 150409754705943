.privacy-container {
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 120px;
  padding-bottom: 80px;
}

.privacy-content {
  padding: 20px;
  max-width: 800px;
  background-color: rgb(17, 18, 31);
  box-shadow: 8px 10px 8px rgba(0, 0, 0, 0.144);
  border-radius: 8px;
  height: auto;
}

.privacy-content h1,
.privacy-content h2,
.privacy-content h3,
.privacy-content p {
  margin-bottom: 20px;
}

.privacy-content h2,
.privacy-content h1 {
  color: #eeeeee;
}

.privacy-content h3 {
  color: #eeeeee;
}

.privacy-content p {
  color: #d6d6d6;
  line-height: 1.6;
}
