.contact-container {
  /* background-color: red; */
  height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
}

.contact-container-form,
.contact-container-info {
  width: 50%;
  display: flex;
  flex-direction: column;
}

.contact-container-info h1 {
  color: white;
  z-index: 1;
}

.contact-container-form {
  /* background-color: blue; */
  height: 441px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact-container-info {
  /* background-color: purple; */
  height: 441px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* ContactForm.css */

.contact-form {
  width: 300px;
  margin: 0 auto;
  padding: 20px;
  background-color: rgb(17, 18, 31);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.contact-form form {
  display: flex;
  flex-direction: column;
}

.contact-form label {
  margin-top: 10px;
  margin-bottom: 5px;
  color: #eeeeee;
}

.contact-form input[type="text"],
.contact-form input[type="email"] {
  height: 25px;
}

.contact-form input[type="text"],
.contact-form input[type="email"],
.contact-form textarea {
  width: 100%;
  padding: 0px;
  margin-bottom: 10px;
  border: 1px solid rgb(27, 28, 39);
  border-radius: 4px;
  font-size: 16px !important;
  padding-left: 5px;
  background-color: rgb(29, 30, 51);
  color: #d6d6d6;
}

.contact-form textarea {
  height: 150px;
  resize: vertical;
  font-size: 16px !important;
  padding-top: 4px;
  font-family: "Roboto", sans-serif;
  background-color: rgb(29, 30, 51);
  color: #d6d6d6;
}

.contact-form button {
  padding: 10px 20px;
  background-color: rgb(8, 46, 129);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.contact-form button:hover {
  background-color: rgb(9, 53, 150);
}

.contact-color2 {
  height: 400px;
  width: 400px;
  background: linear-gradient(to bottom, #398dfa 40%, #1a0a74 50%);
  filter: blur(250px);
  border-radius: 100%;
  position: absolute;
  z-index: 0;
  top: 45%;
  left: 75%;
  transform: translate(-50%, -50%);
}

/* ContactForm.css */

@media screen and (max-width: 1010px) {
  .contact-container {
    height: 885px;
    flex-direction: column;
  }

  .contact-container-info {
    height: 345px;
  }

  .contact-container-form {
    margin-top: 100px;
  }

  .contact-container-form,
  .contact-container-info {
    width: 100%;
  }

  .contact-color2 {
    display: none;
  }
}

@media screen and (max-width: 1300px) {
  .contact-container-form,
  .contact-container-info {
    width: 100%;
  }
}

@media screen and (max-width: 665px) {
  .contact-container-info {
    /* background-color: purple; */
    height: 520px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .contact-container {
    /* background-color: red; */
    height: 1070px;
  }
}

@media screen and (max-width: 560px) {
  .contact-container-info {
    height: 730px;
  }

  .contact-container {
    /* background-color: red; */
    height: 1300px;
  }

  .contact-form {
    width: 80vw;
  }
}

@media screen and (min-width: 1920px) {
  .contact-container-form,
  .contact-container-info {
    /* Adjust the width and height as needed */
    width: 60%; /* Example increase */
    height: 500px; /* Example increase */
  }

  .contact-form {
    width: 400px; /* Example increase */
    padding: 30px; /* Example increase */
  }

  .contact-form input[type="text"],
  .contact-form input[type="email"],
  .contact-form textarea {
    font-size: 18px; /* Example increase */
  }

  .contact-form textarea {
    height: 200px; /* Example increase */
  }

  .contact-form button {
    padding: 15px 30px; /* Example increase */
    font-size: 18px; /* Example increase */
  }

  /* Additional styling adjustments as needed */
}

@media screen and (min-width: 2560px) {
  .contact-container-form,
  .contact-container-info {
    width: 70%; /* Increased size */
    height: 600px; /* Increased size */
  }

  .contact-form {
    width: 500px; /* Increased size */
    padding: 40px; /* Increased size */
  }

  .contact-form input[type="text"],
  .contact-form input[type="email"],
  .contact-form textarea {
    font-size: 20px; /* Increased size */
  }

  .contact-form textarea {
    height: 250px; /* Increased size */
  }

  .contact-form button {
    padding: 20px 40px; /* Increased size */
    font-size: 20px; /* Increased size */
  }

  /* Additional styling adjustments as needed */
}
