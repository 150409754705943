.contactInfo {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 1vh;
  padding-bottom: 20px;
  padding-top: 20px;
  /* background-color: green; */
}

.jcontact-wrapper {
  display: flex;
  align-items: center;
}

.jcontact-img {
  width: 133px;
  height: 133px;
  border-radius: 50%;
  margin-right: -31px;
  z-index: 2;
}

.jcontact-card {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  width: 540px;
  height: 120px;
  background-color: rgb(17, 18, 31);
  border-radius: 30px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.jcontact-card h1 {
  font-size: 23px;
  padding-left: 35px;
  padding-top: 0vh;
  padding-bottom: 0vh;
  color: #ffffff;
}

.h1e {
  padding-right: 26.5px;
}

.p0 {
  padding-left: 40px;
  font-style: italic;
  color: #d6d6d6;
}

.heading-and-subheading {
  flex-direction: row;
}

.p1 {
  margin-left: 65px;
  font-style: normal !important;
  margin-bottom: 0px;
  cursor: pointer;
  color: #eeeeee;
}

.p2 {
  margin-left: 65px !important;
  font-style: normal !important;
  margin-top: 0px;
  cursor: pointer;
  color: #eeeeee;
}

.p1:hover,
.p2:hover {
  color: #007bff;
}

.notifications-container {
  position: fixed;
  bottom: 0;
  right: 10px;
  z-index: 1000;
  font-family: Arial, Helvetica, sans-serif !important;
}

@media screen and (max-width: 1300px) {
  .contactInfo {
    padding-right: 10px;
  }
}

@media screen and (max-width: 1010px) {
  .contactInfo {
    padding-right: 0px;
  }
}

@media screen and (max-width: 768px) {
  .p0 {
    padding-left: 0px;
  }

  .p1 {
    padding-left: 0px;
    margin-left: 15px;
  }
}

@media screen and (max-width: 665px) {
  .jcontact-wrapper {
    flex-direction: column;
  }

  .jcontact-img {
    margin-right: 0px;
    margin-bottom: -10px;
  }
}

@media screen and (max-width: 560px) {
  .jcontact-card {
    flex-direction: column;
    width: 90vw;
    height: 200px;
    align-items: center !important;
    justify-content: center !important;
  }

  .h1e {
    padding-right: 0px;
  }

  .jcontact-card h1 {
    padding-left: 0px;
  }

  .p0 {
    padding-left: 0px;
  }

  .p1 {
    margin-left: 0px !important;
  }

  .p2 {
    margin-left: 0px !important;
  }

  .jcontact-wrapper {
    align-items: center;
  }
}

@media screen and (min-width: 1920px) {
  .jcontact-img {
    width: 160px; /* Example increase */
    height: 160px; /* Example increase */
  }

  .jcontact-card {
    width: 650px; /* Example increase */
    height: 150px; /* Example increase */
  }

  .jcontact-card h1 {
    font-size: 28px; /* Example increase */
  }

  .p0,
  .p1,
  .p2 {
    font-size: 18px; /* Example increase */
    padding-left: 50px; /* Example increase */
  }

  /* Additional styling adjustments as needed */
}

@media screen and (min-width: 2560px) {
  .jcontact-img {
    width: 200px; /* Increased size */
    height: 200px; /* Increased size */
  }

  .jcontact-card {
    width: 800px; /* Increased size */
    height: 180px; /* Increased size */
  }

  .jcontact-card h1 {
    font-size: 32px; /* Increased size */
  }

  .p0,
  .p1,
  .p2 {
    font-size: 20px; /* Increased size */
    padding-left: 60px; /* Increased size */
  }

  /* Additional styling adjustments as needed */
}
