.main {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  position: absolute;
  right: 10px;
  bottom: -6px;
}

.main-ersu-socials {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  position: absolute;
  left: 10px;
  bottom: -6px;
}

.up {
  display: flex;
  flex-direction: row;
  gap: 0.5em;
}

.down {
  display: flex;
  flex-direction: row;
  gap: 0.5em;
}

.card1,
.card2,
.card3,
.card4 {
  width: 80px;
  height: 80px;
  outline: none;
  border: none;
  background: rgb(26, 27, 46);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  transition: 0.2s ease-in-out;
}

.card1 {
  border-radius: 90px 5px 5px 5px;
}

.instagram {
  margin-top: -1.5em;
  margin-left: -1.2em;
  fill: #cc39a4;
  z-index: 1;
}

.card2 {
  border-radius: 5px 90px 5px 5px;
}

.twitter {
  margin-top: 1.26em;
  margin-left: 2.2em;
  fill: #000000;
}

.card3 {
  border-radius: 5px 5px 5px 90px;
}

.linkedin {
  margin-top: 0.85em;
  margin-left: -1.2em;
  fill: #0077b5;
}

.card4 {
  border-radius: 5px 5px 90px 5px;
}

.whatsapp {
  margin-top: 0.9em;
  margin-left: 1.2em;
  fill: #25d366;
}

.card1:hover {
  cursor: pointer;
  scale: 1.1;
  background-color: #25d366;
}

.card1:hover .whatsapp {
  fill: white;
}

.card2:hover {
  cursor: pointer;
  scale: 1.1;
  background-color: #0077b5;
}

.card2:hover .linkedin {
  fill: white;
}

.card3:hover {
  cursor: pointer;
  scale: 1.1;
  background-color: #000000;
}

.card3:hover .twitter {
  fill: white;
}

.card4:hover {
  cursor: pointer;
  scale: 1.1;
  background-color: #cc39a4;
}

.card4:hover .instagram {
  fill: white;
}

@media screen and (max-width: 385px) {
  .card1,
  .card2,
  .card3,
  .card4 {
    width: 60px;
    height: 60px;
    outline: none;
    border: none;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
      rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    transition: 0.2s ease-in-out;
  }

  .twitter {
    margin-top: 0.6em;
    margin-left: 1.6em;
    fill: #000000;
  }

  .whatsapp {
    margin-top: 0.8em;
    margin-left: 1em;
    fill: #25d366;
  }

  .instagram {
    margin-top: -1.7em !important;
    margin-left: -1.2em;
  }

  .main {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    position: absolute;
    right: 10px;
    bottom: -6px;
  }
}

@media screen and (max-width: 352px) {
  .main {
    display: none !important;
  }

  .main-ersu-socials {
    display: none;
  }
}
