.ourstory-container {
  height: auto;
}

.ourstory-content {
  height: auto;
  width: 100%;
  display: flex;
  padding-top: 100px;
  padding-bottom: 100px;
}

.ourstory-card-container {
  height: auto;
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.jetimage-container {
  height: auto;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.ourstory-card {
  background-color: rgb(17, 18, 31);
  height: auto;
  width: 800px;
  padding: 40px;
  border-radius: 30px;
  box-shadow: 8px 10px 8px rgba(0, 0, 0, 0.144);
  margin-right: 1vw;
  margin-left: 1vw;
  z-index: 1;
}

.ourstory-card h1 {
  color: white;
}

.ourstory-card p {
  color: #eeeeee;
}

.jetimage-container img {
  border-radius: 10%;
  margin-right: 1vw;
  z-index: 2;
  box-shadow: 8px 10px 8px rgba(0, 0, 0, 0.144);
}

.ourstory-color {
  height: 300px;
  width: 300px;
  background: linear-gradient(to bottom, #398dfa 40%, #1a0a74 50%);
  filter: blur(200px);
  border-radius: 100%;
  position: absolute;
  z-index: -1;
  top: 200%;
  left: 80%;
  transform: translate(-50%, -50%);
  overflow: hidden !important;
}

.ourstory-color2 {
  height: 300px;
  width: 300px;
  background: linear-gradient(to bottom, #398dfa 40%, #1a0a74 50%);
  filter: blur(220px);
  border-radius: 100%;
  position: absolute;
  z-index: -1;
  top: 130%;
  left: 27%;
  transform: translate(-50%, -50%);
  overflow: hidden !important;
}

.ourstory-color3 {
  height: 300px;
  width: 300px;
  background: linear-gradient(to bottom, #398dfa 40%, #1a0a74 50%);
  filter: blur(180px);
  border-radius: 100%;
  position: absolute;
  z-index: -1;
  top: 340%;
  left: 22%;
  transform: translate(-50%, -50%);
  overflow: hidden !important;
}

.ourstory-color4 {
  height: 300px;
  width: 300px;
  background: linear-gradient(to bottom, #398dfa 40%, #1a0a74 50%);
  filter: blur(220px);
  border-radius: 100%;
  position: absolute;
  z-index: -1;
  top: 400%;
  left: 47%;
  transform: translate(-50%, -50%);
  overflow: hidden !important;
}

@media screen and (min-width: 1199px) and (max-width: 1584px) {
  .jetimage-container img {
    height: 37vw;
  }
}

@media screen and (min-width: 1800px) and (max-width: 2400px) {
  .jetimage-container img {
    height: 700px;
  }
}

@media screen and (min-width: 2400px) {
  .jetimage-container img {
    height: 770px;
  }
}

@media screen and (min-width: 1585px) and (max-width: 1800px) {
  .jetimage-container img {
    height: 622px;
  }
}

@media screen and (max-height: 1585px) {
  .ourstory-card {
    height: auto !important;
  }

  .ourstory-card-container {
    /* background-color: red; */
    align-items: center !important;
    height: auto !important;
  }
}

@media screen and (max-height: 875px) {
  .ourstory-content {
    /* background-color: blue; */
    height: auto;
  }

  .ourstory-card-container {
    /* background-color: red; */
    align-items: normal;
    height: auto;
  }

  .jetimage-container {
    /* background-color: green; */
    align-items: normal;
    height: auto;
  }

  .jetimage-container img {
    margin-top: 50px;
  }

  .ourstory-card {
    height: 520px;
    margin-top: 100px;
  }
}

@media screen and (max-width: 1198px) {
  .ourstory-content {
    /* background-color: blue; */
    flex-direction: column;
    height: auto;
    padding-top: 10px;
  }

  .ourstory-card {
    margin-top: 50px !important;
    height: auto !important;
  }

  .ourstory-card-container {
    /* background-color: red; */
    width: 100%;
    height: auto;
    justify-content: center !important;
  }

  .jetimage-container {
    /* background-color: green; */
    width: 100%;
    height: auto;
    justify-content: center;
  }

  .jetimage-container img {
    /* background-color: green; */
    margin-top: 50px;
    margin-bottom: -85px;
    height: 60vw;
    border-radius: 10%;
    margin-right: 0vw;
    z-index: 2;
  }

  .ourstory-color {
    display: none;
  }

  .ourstory-color2 {
    display: none;
  }

  .ourstory-color3 {
    display: none;
  }

  .ourstory-color4 {
    display: none;
  }
}
