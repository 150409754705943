.joar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 70vh;
  padding-top: 150px;
  flex-direction: column;
}

.h1abt {
  font-size: 45px;
  color: #ffffff;
  letter-spacing: 6px;
  margin-top: -10px;
  z-index: 1;
}

.line1 {
  background-color: rgb(26, 27, 46);
  height: 2.5px;
  width: 85vw;
  margin-top: -30px;
  margin-bottom: 70px;
  z-index: 1;
}

.joar-wrapper {
  display: flex;
  align-items: center;
}

.joar-img {
  width: 620px;
  height: 620px;
  border-radius: 50%;
  margin-right: 2vw;
  box-shadow: 4px 5px 4px rgba(0, 0, 0, 0.082);
  z-index: 2;
}

.joar-card {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  height: 520px;
  width: 800px;
  background-color: rgb(17, 18, 31);
  border-radius: 30px;
  box-shadow: 8px 10px 8px rgba(0, 0, 0, 0.144);
  z-index: 1;
}

.joar-card h1 {
  font-size: 37px;
  padding-right: 30px;
  padding-top: 5px;
  padding-bottom: 0vh;
  color: #ffffff;
}

.pj {
  align-self: flex-end;
  padding-right: 35px;
  font-style: italic;
  color: #eeeeee;
}

.joar-card .p1j {
  padding-left: 35px;
  padding-right: 35px;
  font-style: normal;
  text-align: right;
  color: #eeeeee;
}

.joar-card .p2j-contact {
  align-self: flex-start;
  padding-left: 52px;
  text-align: left;
  margin-top: 65px;
  font-size: 14.5px;
  font-style: normal;
  color: #eeeeee;
}

@media screen and (max-width: 1170px) {
  .joar-wrapper {
    flex-direction: column;
    align-items: center;
  }

  .joar-card .p2j-contact {
    align-self: flex-start;
    padding-left: 15px;
    text-align: left;
    bottom: 5px;
    font-size: 14.5px;
    font-style: normal;
    position: absolute;
  }

  .joar-container {
    min-height: 100vh;
    padding-top: 80px;
  }

  .joar-card h1 {
    font-size: 25px;
  }

  .joar-img {
    order: 1;
    width: 60vw;
    height: auto;
    margin-bottom: -20px;
    margin-right: 0;
  }

  .joar-card .p1j {
    padding-bottom: 5px;
    font-style: normal;
    text-align: center;
  }

  .joar-card {
    order: 2;
    width: 90vw;
    height: auto;
    padding-bottom: 170px;
  }
}

@media screen and (max-width: 1470px) {
  .joar-img {
    width: 330px;
    height: 330px;
  }
}

@media screen and (max-width: 385px) {
  .joar-card {
    padding-bottom: 130px;
  }
}

@media screen and (max-width: 356px) {
  .joar-card {
    height: auto;
  }

  .joar-img {
    width: 260px;
    height: 260px;
  }
}
