.clients-container {
  /* background-color: red; */
  height: auto; /* increase page size */
}

.clients-content {
  /* background-color: blue; */
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 150px;
}

.client-title-container {
  /* background-color: green; */
  height: 100px;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.client-card-container {
  /*background-color: purple;*/
  width: 88vw;
  padding: 1vw;
  height: auto;
  display: flex;
  gap: 21.5px;
  flex-wrap: wrap;
  justify-content: center;
}

.clients-content h1 {
  font-size: 45px;
  color: #ffffff;
  letter-spacing: 6px;
  margin-top: -10px;
  z-index: 1;
}

.clients-border {
  background-color: rgb(39, 39, 49);
  height: 2.5px;
  width: 85vw;
  margin-top: -20px;
}

@media screen and (max-width: 1170px) {
  .clients-content {
    padding-top: 60px;
  }
}
