body {
  font-family: Arial, sans-serif;
  background-color: #2a2a2a;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  height: 60px;
  backdrop-filter: blur(5px);
}

.navlink {
  text-decoration: none !important;
  color: white !important;
}

.navlink span {
  text-decoration: none !important;
  color: white !important;
}

.left-section {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 50%;
}

.right-section {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 50%;
}

.logo-container {
  z-index: 15;
}

.logo-button img {
  width: 150px;
  height: auto;
  display: block;
  margin: 0 auto;
  margin-top: 0px;
}

.logo-button button:hover {
  background: none;
  color: transparent;
}

.left-section span,
.right-section span {
  margin: 0 15px;
  cursor: pointer;
  transition: color 0.3s;
}

.left-section span:hover,
.right-section span:hover {
  color: #ddd;
}

.navbutton {
  font-size: 20px;
  color: #e1e1e1;
  font-family: "Roboto", sans-serif !important;
  font-weight: 700 !important;
  letter-spacing: 2px;
  cursor: pointer;
  position: relative;
  border: none;
  background: none;
  text-transform: uppercase;
  transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
  transition-duration: 400ms;
  transition-property: color;
}

.navbutton:focus,
.navbutton:hover {
  color: #fff;
}

.navbutton:focus:after,
.navbutton:hover:after {
  width: 74%;
  left: 13%;
}

.navbutton:after {
  content: "";
  pointer-events: none;
  bottom: -2px;
  left: 50%;
  position: absolute;
  width: 0%;
  height: 2px;
  background-color: #fff;
  transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
  transition-duration: 400ms;
  transition-property: width, left;
}

.menu-button {
  display: none;
}

.dropdown-menu {
  display: none;
  padding: 0;
  margin-top: 4vw;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: opacity 0.3s ease, transform 0.3s ease;
  transform: translateY(-20px);
  opacity: 0;
  text-align: center;
}

.dropdown-menu.show {
  display: block;
  opacity: 1;
  transform: translateY(0);
}

.dropdown-menu a {
  z-index: 100 !important;
  padding: 15px 20px;
  text-decoration: none;
  color: #333333;
  font-size: 14px;
  font-family: "Helvetica Neue", sans-serif;
  border-bottom: 1px solid #eaeaea;
  border-radius: 10px;
}

.dropdown-menu a:last-child {
  border-bottom: none;
}

.dropdown-menu a:hover {
  background-color: #f5f5f5;
  color: #1a1a1a;
  border-radius: 10px;
}

.menu-container {
  display: inline-block;
  cursor: pointer;
}

.bar1,
.bar2,
.bar3 {
  width: 35px;
  height: 5px;
  background-color: #ffffff;
  margin: 6px 0;
  transition: 0.4s;
}

.change .bar1 {
  transform: translate(0, 11px) rotate(-45deg);
}

.change .bar2 {
  opacity: 0;
}

.change .bar3 {
  transform: translate(0, -11px) rotate(45deg);
}

@media screen and (max-width: 1170px) {
  .left-section,
  .logo-button,
  .right-section {
    padding: 5px 0;
  }

  .left-section span,
  .right-section span {
    margin: 0 10px;
  }

  .navbutton {
    display: none;
  }

  .menu-button {
    display: block;
    background: transparent;
    border: none;
  }

  .dropdown-menu {
    display: none;
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    background-color: #fff;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    transition: all 0.3s ease;
  }

  .dropdown-menu.show {
    display: block;
  }

  .dropdown-menu a {
    display: block;
    padding: 10px 20px;
    text-decoration: none;
    color: black;
    transition: background-color 0.3s ease;
  }

  .dropdown-menu a:hover {
    background-color: #ddd;
  }
}
