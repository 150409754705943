.footer {
  background-color: #f8f9fa;
  color: rgb(40, 40, 40);
  padding: 20px 0;
  position: relative;
}

.footer-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
  padding-top: 6vh;
  position: relative;
  border-top: solid;
  border-color: rgb(26, 27, 46);
}

.row {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
}

.col {
  flex: 1;
  padding: 15px;
  min-width: 200px;
}

h3 {
  color: #eeeeee;
  margin-bottom: 15px;
}

p {
  color: #d6d6d6;
  text-decoration: none;
  line-height: 1.6;
}

a {
  color: #d6d6d6;
  text-decoration: none;
  line-height: 1.6;
}

a:hover {
  color: #007bff;
}

ul {
  list-style: none;
  padding: 0;
}

li {
  margin-bottom: 10px;
}

@media screen and (max-width: 768px) {
  .footer {
    padding: 10px 0;
  }

  .footer-container {
    padding: 0 10px;
  }

  .row {
    flex-direction: column;
    align-items: center;
  }

  .col {
    min-width: auto;
    padding: 10px;
    flex: 1 1 auto;
    max-width: 100%;
    box-sizing: border-box;
  }

  h3,
  p,
  a {
    text-align: center;
  }

  ul {
    padding: 0;
    text-align: center;
  }

  li {
    text-align: center;
  }
}
