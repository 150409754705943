@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");

.aircraft-card {
  background-color: rgb(17, 18, 31);
  height: 550px;
  width: 280px;
  padding-top: 1px;
  padding-bottom: 1px;
  padding-right: 20px;
  padding-left: 20px;
  border-radius: 20px;
  box-shadow: 4px 5px 4px rgba(0, 0, 0, 0.055);
  z-index: 1;
}

.aircraft-card-border {
  height: 2px;
  width: 280px;
  background-color: rgb(29, 30, 51);
  margin-top: -7px;
}

.ac-p-container {
  /*background-color: green;*/
  height: 165px;
}

.aircraft-card img {
  padding-top: 17px;
  height: 150px;
  width: auto;
}

.aircraft-card p {
  color: #eeeeee;
  font-size: 17px;
}

.aircraft-card-tags-container {
  /* background-color: blue; */
  width: 280px;
  height: 75px;
  display: flex;
  flex-wrap: wrap;
  cursor: default;
}

.aircraft-card-tags-container > p {
  color: #eeeeee;
  margin: 5px;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 2px;
  font-size: 15px;
  font-weight: 500;
  background-color: rgb(21, 22, 37);
  border-radius: 5px;
  display: inline-block;
}

.aircraft-card-button-container {
  height: 50px;
  width: 280px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.aircraft-card-button {
  height: 45px !important;
  width: 280px !important;
  font-family: "Roboto", sans-serif !important;
  letter-spacing: 3px !important;
  font-weight: 1000 !important;
  font-size: 14px !important;
  color: white !important;
  background-color: rgb(14, 15, 26) !important;
  border: none !important;
  border-radius: 10px !important;
  cursor: pointer !important;
  margin-top: 8.5px !important;
  transition: ease-in-out 0.2s !important;
}

.aircraft-card-button:hover {
  background-color: rgb(21, 22, 37) !important;
}
