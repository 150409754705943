.ersu-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 0vh;
  padding-top: 60px;
}

.ersu-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;
}

.ersu-img {
  width: 620px;
  height: 620px;
  border-radius: 50%;
  margin-left: 2vw;
  box-shadow: 4px 5px 4px rgba(0, 0, 0, 0.082);
  z-index: 1;
}

.ersu-card {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 520px;
  width: 800px;
  background-color: rgb(17, 18, 31);
  border-radius: 30px;
  box-shadow: 8px 10px 8px rgba(0, 0, 0, 0.144);
  text-align: left;
  z-index: 0;
}

.ersu-card h1 {
  font-size: 37px;
  padding-left: 30px;
  padding-top: 5px;
  padding-bottom: 0vh;
  color: #ffffff;
}

.pe {
  align-self: flex-start;
  padding-left: 35px;
  font-style: italic;
  color: #eeeeee;
}

.ersu-card .p1e {
  padding-left: 35px;
  padding-right: 35px;
  font-style: normal;
  color: #eeeeee;
}

.ersu-card .p2e-contact {
  align-self: flex-end;
  padding-right: 52px;
  text-align: right;
  margin-top: 65px;
  font-size: 14.5px;
  font-style: normal;
  color: #eeeeee;
}

@media screen and (max-width: 1170px) {
  .ersu-card .p2e-contact {
    align-self: flex-start;
    padding-right: 15px;
    text-align: right;
    bottom: 5px;
    right: 0px;
    font-size: 14.5px;
    font-style: normal;
    position: absolute;
  }

  .ersu-wrapper {
    flex-direction: column;
    align-items: center;
  }

  .ersu-container {
    padding-top: 50px;
  }

  .ersu-card h1 {
    font-size: 25px;
  }

  .ersu-card .p1e {
    padding-bottom: 5px;
    font-style: normal;
    text-align: center;
  }

  .ersu-img {
    order: 1;
    margin-bottom: -20px;
    margin-left: 0;
    z-index: 1;
  }

  .ersu-card {
    order: 2;
    width: 90vw;
    height: auto;
    padding-bottom: 170px;
  }
}

@media screen and (max-width: 1470px) {
  .ersu-img {
    width: 330px;
    height: 330px;
  }
}

@media screen and (max-width: 385px) {
  .ersu-card {
    padding-bottom: 130px;
  }
}

@media screen and (max-width: 356px) {
  .ersu-card {
    height: auto;
  }

  .ersu-img {
    width: 260px;
    height: 260px;
  }
}
